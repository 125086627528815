@use './select-panel' as select;
@use 'vars' as vars;
@use 'typography' as t;

.mat-mdc-form-field.mdc-form-field-select--light {
  &.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
    --mdc-select-padding-left: 12px;
    --mdc-select-padding-right: 14px;
    --mdc-outlined-text-field-outline-color: vars.$light-primary-text;
    --mdc-outlined-text-field-hover-outline-color: vars.$light-primary-text;
    --mdc-outlined-text-field-focus-outline-color: vars.$light-primary-text;
    color: vars.$light-primary-text;
    height: 40px;

    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-icon-prefix {
        padding: 0 10px 0 0;

        mat-icon {
          padding: 0;
        }
      }
    }

    .mat-mdc-form-field-infix {
      padding: 8px 0;
      min-height: unset;

      .mat-mdc-select {
        .mat-mdc-select-value {
          @include t.typography('body-2');
          color: vars.$light-primary-text;
          padding-right: 10px;
        }
        .mat-mdc-select-arrow:before {
          color: vars.$light-primary-text;
        }
      }
    }
  }
}

.mat-mdc-form-field.mdc-form-field-select--dark {
  --mat-icon-color: vars.$light-primary-text;

  .mat-mdc-select {
    --mat-select-enabled-trigger-text-color: vars.$light-primary-text;
    --mat-select-trigger-text-tracking: normal;

    &:focus {
      .mat-mdc-select-arrow {
        &::before {
          transform: scaleY(-1);
        }
      }
    }
  }

  &.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
    --mdc-outlined-text-field-outline-color: vars.$light-primary-text;
    --mdc-outlined-text-field-hover-outline-color: vars.$light-primary-text;
    --mdc-outlined-text-field-focus-outline-color: vars.$light-primary-text;
    --mat-select-trigger-text-size: 14px;

    transition: background-color 150ms ease-in-out;
    height: 40px;

    &.mdc-text-field--focused {
      background-color: vars.$primary-mia-blue;
    }

    &:hover {
      background-color: vars.$primary-mia-blue;
    }

    .mat-mdc-form-field-icon-prefix {
      padding: 0 2px 0 4px;

      & > .mat-icon {
        padding: 8px;
      }
    }

    .mat-mdc-form-field-infix {
      padding-top: 8px;
      padding-bottom: 8px;
      width: 117px;
      min-height: 32px;
    }
  }

  &.mat-focused {
    .mat-mdc-select-arrow {
      &::before {
        transform: scaleY(-1);
      }
    }
  }

  .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
    display: none;
  }
}

.mat-mdc-form-field.mat-mdc-form-field {
  &.mdc-text-field--no-border {
    .mdc-text-field--outlined {
      --mdc-outlined-text-field-outline-width: 0;
      --mdc-outlined-text-field-focus-outline-width: 0;
    }
  }
  .mat-mdc-select {
    .mat-mdc-select-arrow-wrapper {
      align-items: unset;
      width: auto;
      height: auto;

      .mat-mdc-select-arrow {
        width: 18px;
        height: 20px;

        svg {
          display: none;
        }

        &::before {
          font-family: 'Material Icons Round';
          position: absolute;
          content: 'arrow_back_ios';
          transform: rotate(-90deg);
          color: var(--mat-icon-color);
          font-size: 20px;
          line-height: 20px;
          transition: transform 200ms ease-in-out;
        }
      }
    }
  }

  &.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
    padding-left: var(--mdc-select-padding-left, 16px);
    padding-right: var(--mdc-select-padding-right, 16px);
    &.mdc-text-field--focused {
      .mat-mdc-select-arrow {
        &::before {
          transform: rotate(90deg);
        }
      }
    }

    .mat-mdc-form-field-icon-prefix {
      padding: 0 8px 0 0;
    }

    .mat-mdc-form-field-infix {
      width: auto;
    }

    .mat-mdc-select {
      width: calc(
        var(--mdc-select-width, 117px) - var(--mdc-select-padding-left, 16px) -
          var(--mdc-select-padding-right, 16px)
      );
    }
  }
}

.mat-mdc-form-field.mat-mdc-form-field-type-mat-input:hover {
  --mdc-outlined-text-field-hover-outline-color: #{vars.$primary-mia-blue};
}

.mat-mdc-form-field.mdc-form-field-select {
  padding-top: 20px;
  --mdc-outlined-text-field-focus-outline-color: #{vars.$primary-mia-blue};
  --mat-select-focused-arrow-color: #{vars.$secondary-gray-03};
  --mat-select-disabled-trigger-text-color: #{vars.$secondary-gray-03};

  &:hover:not(&.mat-form-field-disabled) {
    --mdc-outlined-text-field-hover-outline-color: #{vars.$primary-mia-blue};
    --mat-icon-color: #{vars.$primary-mia-blue};
  }

  &:has(.mat-select--has-value):not(&.mat-form-field-disabled) {
    --mdc-outlined-text-field-outline-color: #{vars.$primary-mia-blue};
  }

  .mat-mdc-select {
    margin-top: -2px;
    &.mat-mdc-select-disabled
      .mat-mdc-select-trigger
      .mat-mdc-select-value
      .mat-mdc-select-placeholder {
      color: vars.$secondary-gray-03;
    }
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
    &.mdc-text-field--invalid:not(.mdc-text-field--disabled) {
      .mdc-notched-outline--notched .mdc-notched-outline__notch {
        border-top-color: var(--mdc-outlined-text-field-error-outline-color);
      }
    }

    &.mdc-text-field--focused:not(
        .mdc-text-field--disabled,
        .mdc-text-field--invalid
      ) {
      .mdc-notched-outline--notched .mdc-notched-outline__notch {
        border-top-color: var(--mdc-outlined-text-field-focus-outline-color);
      }
    }

    .mdc-notched-outline--notched .mdc-notched-outline__notch {
      border-top: solid;
      border-top-width: var(--mdc-outlined-text-field-outline-width);
      border-top-color: var(--mdc-outlined-text-field-outline-color);
    }

    .mat-mdc-form-field-infix {
      --mat-select-enabled-trigger-text-color: #{vars.$primary-mia-purple};

      .mdc-form-field-select-label {
        @include t.typography('body-2-bold');
        position: absolute;
        top: -25px;
        left: -15px;

        &.-thin {
          color: vars.$secondary-gray-03;
          font-weight: 500;

           > .required {
            color: vars.$accent-warning;
          }
        }
      }

      .mat-mdc-select-placeholder {
        @include t.typography('body-2');
        color: vars.$primary-mia-dark-grey;
      }

      .mat-mdc-select-min-line:not(.mat-mdc-select-placeholder) {
        @include t.typography('body-2');
      }
    }
  }
}

.mat-mdc-form-field.mat-mdc-form-field {
  .mat-mdc-select {
    .mat-mdc-select-arrow-wrapper {
      align-items: unset;
      width: auto;
      height: auto;

      .mat-mdc-select-arrow {
        width: 18px;
        height: 20px;

        svg {
          display: none;
        }

        &::before {
          font-family: 'Material Icons Round';
          position: absolute;
          content: 'arrow_back_ios';
          transform: rotate(-90deg);
          color: var(--mat-icon-color);
          font-size: 20px;
          line-height: 20px;
          transition: transform 200ms ease-in-out;
        }
      }
    }
  }

  &.mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
    &.mdc-text-field--focused {
      .mat-mdc-select-arrow {
        &::before {
          transform: rotate(90deg);
        }
      }
    }

    .mat-mdc-form-field-icon-prefix {
      padding: 0 8px 0 0;
    }

    .mat-mdc-form-field-infix {
      width: auto;
    }

    .mat-mdc-select {
      width: calc(
        var(--mdc-select-width, 117px) - var(--mdc-select-padding-left, 16px) -
          var(--mdc-select-padding-right, 16px)
      );
    }
  }
}

.mat-mdc-form-field.mdc-form-field-select--no-hint {
  .mat-mdc-select-min-line {
    @include t.typography('body-2');
  }

  .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
    display: none;
  }
}

.mat-select-time {
  --mdc-select-width: 144px;
  --mdc-select-padding-left: 22px;
  --mdc-select-padding-right: 24px;
  .mdc-text-field--outlined {
    --mdc-outlined-text-field-label-text-color: #{vars.$primary-mia-dark-grey};

    &:hover {
      --mdc-outlined-text-field-hover-outline-color: #{vars.$primary-mia-blue};
      --mcd-select-time-unit-bg-color: #{vars.$primary-mia-blue};

      .mat-select-time__hours,
      .mat-select-time__minutes {
        color: vars.$light-primary-text;
      }
    }
  }
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    width: calc(var(--mdc-select-padding-left) - 4px);
  }

  &__hours,
  &__minutes {
    background-color: var(--mcd-select-time-unit-bg-color, transparent);
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
    padding: 0 2px;
    border-radius: 4px;
    text-align: center;
    vertical-align: middle;
  }
  &__hours {
    margin-right: 2px;
  }
  &__minutes {
    margin-left: 2px;
  }

  &.mat-form-field-disabled {
    --mat-select-disabled-trigger-text-color: #{vars.$secondary-gray-03};
  }

  &.mat-focused {
    --mcd-select-time-unit-bg-color: #{vars.$primary-mia-dark-grey};
    .mat-select-time__hours,
    .mat-select-time__minutes {
      color: vars.$light-primary-text;
    }
  }
}
