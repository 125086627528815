// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use './styles/typography' as typography;
@use './styles/vars' as vars;
@use './styles/mixins' as mixins;
@use './styles/palettes' as palettes;

@import './styles/reset';

@import './styles/components/button';
@import './styles/components/checkbox';
@import './styles/components/textfield';
@import './styles/components/select';
@import './styles/components/system-alert';
@import './styles/components/dialog.scss';
@import './styles/components/tooltip.scss';
@import './styles/components/main-table';
@import './styles/components/accordion';
@import './styles/components/menu';
@import './styles/components/datepicker';
@import './styles/components/chip';
@import './styles/components/icon';
@import './styles/components/chats';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$portal-primary: mat.define-palette(palettes.$mia-palette, 700, 900);
$portal-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$portal-warn: mat.define-palette(palettes.$mia-palette-warn);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$portal-theme: mat.define-light-theme(
  (
    color: (
      primary: $portal-primary,
      accent: $portal-accent,
      warn: $portal-warn,
    ),
    typography: typography.$typographyTheme,
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($portal-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  color: vars.$primary-mia-dark-grey;
}
body {
  margin: 0;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  color: vars.$primary-mia-dark-grey;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 100%;
  &:has(span) {
    display: flex;
    flex-direction: column;
  }

  & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 100%;
  }

  &[data-rows] {
    @include mixins.text-ellipsis(2);

    & > * {
      @include mixins.text-ellipsis(2);
    }
  }

  &-tooltip {
    cursor: pointer;
    transition: color 150ms ease-in-out;
    @include typography.typography('body-2');

    &:hover {
      color: vars.$primary-mia-blue;
      font-weight: 700 !important;

      & > span {
        font-weight: 700 !important;
        color: vars.$primary-mia-blue !important;
      }
    }

    &-content {
      @include typography.typography('body-3');
      color: #fff;
      background: vars.$primary-mia-blue;
      padding: 6px;
      position: fixed;
      border-radius: 4px;
      width: 200px;
      z-index: 999;
      word-break: break-word;
    }
  }
}

.cdk-overlay-dark-backdrop {
  background-color: transparentize(vars.$primary-mia-dark-grey, 0.4);
}

// here will be styles for safari only
.safari {
  // Disable scroll when overlay present for Safari
  &:has(.cdk-overlay-transparent-backdrop) app-page {
    overflow: hidden;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

input {
  -moz-appearance: textfield;
}
