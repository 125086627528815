@use 'vars' as vars;
@use 'typography' as typography;

.mdc-dialog__container {
  .mdc-dialog__surface {
    --mdc-dialog-container-color: white;
    --mdc-dialog-subhead-color: #{vars.$primary-mia-dark-grey};
    --mdc-dialog-supporting-text-color: #{vars.$primary-mia-dark-grey};

    --mdc-dialog-container-shape: 8px;
    --mdc-dialog-container-elevation-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
    --mdc-dialog-container-elevation: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);

    .mat-mdc-dialog-title {
      @include typography.typography('subtitle-1-bold');
      padding: 28px 28px 24px;
      word-break: break-word;
      justify-content: space-between;

      &:before {
        display: none;
      }
    }
    .mat-mdc-dialog-content {
      padding: 0 28px 20px;
      word-break: break-word;
      @include typography.typography('body-1');
    }

    .mat-mdc-dialog-actions {
      padding: 0 28px 28px;
      justify-content: flex-end;
      column-gap: 16px;
    }
  }
}
