@use 'vars' as v;
@use 'typography' as t;

.mat-expansion-indicator {
  display: none;
}

.accordion .mat-expansion-panel {
  --mat-expansion-header-collapsed-state-height: 62px;
  --mat-expansion-header-hover-state-layer-color: transparent;

  border: 1px solid v.$secondary-gray-03;
  border-radius: 12px !important;
  box-shadow: none !important;

  &-body {
    padding: 0 24px 20px;
  }

  .collapse-btn,
  .expand-btn {
    @include t.typography('body-2-bold');

    display: flex;
    align-items: center;
    column-gap: 4px;
    padding: 0 0 0 10px;
    border: none;
    background: none;
    color: v.$primary-mia-purple;
    cursor: pointer;
    user-select: none;

    mat-icon {
      display: flex;
      width: 20px;
      height: 20px;
      --mat-icon-color: #{v.$primary-mia-purple};
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .expand-btn {
    svg {
      transform: rotate(180deg);
    }
  }
}

.accordion-title {
  @include t.typography('body-1-bold');

  color: v.$primary-mia-dark-grey;
}
