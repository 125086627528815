@use 'vars' as vars;

.mat-mdc-checkbox.mat-primary {
  .mdc-form-field {
    align-items: unset;
    --mdc-theme-text-primary-on-background: #{vars.$primary-mia-dark-grey};

    & > label {
      padding-left: 12px;
    }
  }

  .mdc-checkbox {
    --mdc-checkbox-state-layer-size: 30px;
    --mdc-checkbox-unselected-icon-color: #{vars.$primary-mia-dark-grey};
    --mdc-checkbox-selected-icon-color: #{vars.$primary-mia-dark-grey};
    --mdc-checkbox-selected-focus-icon-color: #{vars.$primary-mia-blue};
    --mdc-checkbox-selected-hover-icon-color: #{vars.$primary-mia-blue};

    &__ripple {
      display: none;
    }

    &__background {
      border-width: 1px;
      border-radius: 4px;
      width: 30px;
      height: 30px;
      top: calc((var(--mdc-checkbox-state-layer-size) - 30px) / 2);
      left: calc((var(--mdc-checkbox-state-layer-size) - 30px) / 2);

      svg {
        display: none;
      }
    }

    &__native-control:enabled ~.mdc-checkbox__background .mdc-checkbox__mixedmark {
      background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"><path d="M7.26025 10.5457H13.9269M18.9269 10.5457C18.9269 15.1481 15.196 18.8791 10.5936 18.8791C5.99121 18.8791 2.26025 15.1481 2.26025 10.5457C2.26025 5.94336 5.99121 2.2124 10.5936 2.2124C15.196 2.2124 18.9269 5.94336 18.9269 10.5457Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      width: 100%;
      height: 100%;
      border-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &.medium {
    .mdc-checkbox {
      --mdc-checkbox-state-layer-size: 24px;

      &__background {
        height: 24px;
        width: 24px;
        top: 0;
        left: 0;
      }
    }
  }

  &.mat-mdc-checkbox-checked {
    .mdc-checkbox {
      &__background {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23fff'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M20.6864 6.45102C21.295 7.05237 21.295 8.02736 20.6864 8.62871L12.2173 16.9968C12.209 17.0051 12.2005 17.0135 12.1918 17.022C12.0606 17.1518 11.9039 17.3067 11.7545 17.432C11.5814 17.5772 11.3187 17.7706 10.9561 17.887C10.4866 18.0377 9.98089 18.0377 9.51139 17.887C9.14884 17.7706 8.88609 17.5772 8.71299 17.432C8.56361 17.3067 8.40691 17.1518 8.2757 17.022C8.26709 17.0135 8.25858 17.0051 8.25019 16.9968L4.45646 13.2483C3.84785 12.647 3.84785 11.672 4.45646 11.0706C5.06507 10.4693 6.05182 10.4693 6.66043 11.0706L10.2338 14.6014L18.4824 6.45102C19.091 5.84966 20.0778 5.84966 20.6864 6.45102Z'/%3E%3C/svg%3E");
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  &.mat-mdc-checkbox-disabled {
    --mdc-checkbox-disabled-unselected-icon-color: #{vars.$secondary-gray-02};
    &.mat-mdc-checkbox-checked {
      .mdc-checkbox {
        .mdc-checkbox__native-control[disabled]:checked
          ~ .mdc-checkbox__background {
          --mdc-checkbox-disabled-selected-icon-color: transparent;
          border-color: vars.$secondary-gray-02;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%2379798A'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M20.6864 6.45102C21.295 7.05237 21.295 8.02736 20.6864 8.62871L12.2173 16.9968C12.209 17.0051 12.2005 17.0135 12.1918 17.022C12.0606 17.1518 11.9039 17.3067 11.7545 17.432C11.5814 17.5772 11.3187 17.7706 10.9561 17.887C10.4866 18.0377 9.98089 18.0377 9.51139 17.887C9.14884 17.7706 8.88609 17.5772 8.71299 17.432C8.56361 17.3067 8.40691 17.1518 8.2757 17.022C8.26709 17.0135 8.25858 17.0051 8.25019 16.9968L4.45646 13.2483C3.84785 12.647 3.84785 11.672 4.45646 11.0706C5.06507 10.4693 6.05182 10.4693 6.66043 11.0706L10.2338 14.6014L18.4824 6.45102C19.091 5.84966 20.0778 5.84966 20.6864 6.45102Z'/%3E%3C/svg%3E");
        }
      }
    }

    label {
      color: vars.$secondary-gray-03;
    }
  }

  &:hover {
    .mdc-form-field {
      --mdc-theme-text-primary-on-background: #{vars.$primary-mia-blue};
    }

    .mdc-checkbox {
      --mdc-checkbox-unselected-icon-color: #{vars.$primary-mia-blue};
    }
  }
}
