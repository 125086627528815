@use 'typography' as t;
@use 'vars' as v;
@use 'mixins' as m;

.mat-mdc-menu-panel.dropdown {
  min-width: unset;
  max-width: var(--mdc-menu-width, unset);
  width: var(--mdc-menu-width, unset);
  margin: 12px 0;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid v.$secondary-gray-03;

  &.table-config {
    min-width: 188px;
    max-height: min(505px, 80vh);

    .mdc-label {
      @include m.text-ellipsis();
      max-width: 300px;
    }
  }

  &.mat-mdc-elevation-specific {
    box-shadow: none;
  }
  .mat-mdc-menu-content {
    padding: 0;

    .mat-mdc-menu-item {
      background: v.$secondary-gray-01;
      background: var(--mdc-menu-item-background, v.$secondary-gray-01);
      padding: 6px 10px;
      height: 32px;
      min-height: unset;
      border-radius: 4px;
      --mat-icon-color: var(--mdc-menu-item-color, #{v.$primary-mia-dark-grey});

      &.error {
        --mdc-menu-item-color: #{v.$accent-warning};
      }

      &:has(.mat-mdc-checkbox-checked) {
        box-shadow: inset 0 0 0 1px v.$primary-mia-purple;
        --mdc-menu-item-background: #{v.$accent-purple-01};

        &:hover {
          box-shadow: none;
        }
      }

      .mat-mdc-menu-item-text {
        color: var(--mdc-menu-item-color, v.$primary-mia-dark-grey);
        @include t.typography('body-2');
        font-weight: var(--mdc-menu-item-font-weight, 500);
        width: 100%;
      }

      .mat-icon {
        margin-right: var(--mdc-menu-item-icon-margin-right, 6px);
        font-size: 20px;
        display: flex;
        align-items: center;
        width: var(--mdc-menu-item-icon-size, 20px);
        height: var(--mdc-menu-item-icon-size, 20px);
      }

      &:not(:last-of-type) {
        margin-bottom: 6px;
      }

      &:hover {
        --mdc-menu-item-color: #{v.$light-primary-text};
        background: v.$primary-mia-blue;
      }

      &:active {
        --mdc-menu-item-color: #{v.$primary-mia-dark-grey};
        background-color: v.$accent-purple-01;
        border: 1px solid v.$primary-mia-purple;

        &.error {
          --mdc-menu-item-color: #{v.$accent-warning};
        }
      }

      &:has(.mat-menu__checkbox) {
        height: 44px;
        --mdc-checkbox-state-layer-size: 20px;

        &:hover {
          --mdc-theme-text-primary-on-background: #fff;
          .mdc-checkbox {
            .mdc-checkbox__background {
              background-color: #fff;
            }
          }
        }
        .mat-mdc-checkbox-checked {
          .mdc-checkbox {
            .mdc-checkbox__background {
              background-color: v.$primary-mia-dark-grey;
              &::after {
                content: '';
                position: absolute;
                left: 1px;
                display: block;
                width: 20px;
                height: 20px;
                background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.2387 5.37585C17.7458 5.87698 17.7458 6.68947 17.2387 7.19059L10.1811 14.164C10.1741 14.1709 10.167 14.1779 10.1599 14.185C10.0505 14.2931 9.91993 14.4223 9.79545 14.5267C9.6512 14.6477 9.43224 14.8088 9.13011 14.9058C8.73886 15.0314 8.3174 15.0314 7.92615 14.9058C7.62403 14.8088 7.40507 14.6477 7.26082 14.5267C7.13634 14.4223 7.00576 14.2932 6.89641 14.185C6.88923 14.1779 6.88214 14.1709 6.87516 14.164L3.71371 11.0403C3.20653 10.5391 3.20653 9.72664 3.71371 9.22551C4.22088 8.72438 5.04318 8.72438 5.55035 9.22551L8.52813 12.1678L15.402 5.37585C15.9092 4.87472 16.7315 4.87472 17.2387 5.37585Z" fill="white"/></svg>');
              }
            }
          }
          &:hover {
            .mdc-checkbox {
              .mdc-checkbox__background {
                background-color: v.$primary-mia-dark-grey;
              }
            }
          }
        }
        .mdc-checkbox {
          width: 24px;
          height: 24px;
          flex: 0 0 24px;
          .mdc-checkbox__native-control:enabled:not(:checked):not(
              :indeterminate
            )
            ~ .mdc-checkbox__background {
            background: #fff;
          }

          svg {
            display: none;
          }

          .mdc-checkbox__background {
            border-color: v.$secondary-gray-03;
            border-radius: 4px;
            border-width: 1px;
            width: 24px;
            height: 24px;
            box-sizing: border-box;
            &:hover {
              background-color: #fff;
              border-color: v.$secondary-gray-03;
            }

            &:enabled {
              background: #fff;
            }
          }

          .mat-ripple {
            display: none;
          }
        }
        .mdc-form-field {
          width: 100%;
        }
        .mdc-label {
          color: var(--mdc-menu-item-color, v.$primary-mia-dark-grey);
          margin-left: 8px;
          width: 100%;
        }
      }
    }
  }
}
