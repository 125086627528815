@use 'vars' as v;

mat-icon.mat-icon {
  height: var(--mat-icon-size, 24px);
  width: var(--mat-icon-size, 24px);
  svg {
    fill: var(--mat-icon-color, #{v.$primary-mia-dark-grey});
    stroke: var(--mat-icon-color, #{v.$primary-mia-dark-grey});
    color: var(--mat-icon-color, #{v.$primary-mia-dark-grey});
  }
}
