@use 'sass:map';
@use 'vars' as vars;

@mixin background-opacity($color, $opacity: 0.5) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}

@mixin text-ellipsis($rows: null) {
  @if $rows {
    display: -webkit-box;
    -webkit-line-clamp: $rows;
    -webkit-box-orient: vertical;
    line-clamp: $rows;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: unset;
  } @else {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

@mixin mq($key) {
  $size: map.get(vars.$break-points, $key);

  @media (min-width: $size) {
    @content;
  }
}

@mixin mq-max($key) {
  $size: map.get(vars.$break-points, $key);

  @media (max-width: $size) {
    @content;
  }
}

@function bg-opacity($color, $opacity: 0.5) {
  @return rgba($color, $opacity);
}
