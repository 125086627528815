@use 'sass:map';
@use 'typography' as typography;
@use 'vars' as vars;

.mdc-tooltip.mat-mdc-tooltip {
  &.text-tooltip {
    --mdc-plain-tooltip-container-color: #{vars.$primary-mia-blue};
    --mdc-plain-tooltip-supporting-text-color: #{vars.$light-primary-text};

    .mdc-tooltip__surface {
      padding: 6px;
    }
  }

  --mdc-plain-tooltip-supporting-text-size: #{map.get(
      typography.$body-2,
      'font-size'
    )};
  --mdc-plain-tooltip-supporting-text-line-height: #{map.get(
      typography.$body-2,
      'line-height'
    )};

  --mdc-plain-tooltip-supporting-text-tracking: 0;

  .mdc-tooltip__surface {
    padding: 12px;
  }
}
