@use 'vars' as v;
@use 'typography' as t;

.chat-avatar {
  --mat-icon-color: #{v.$primary-mia-purple};
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-color: v.$accent-purple-01;
  background-position: center;
  border-radius: 50%;
  flex: none;
}

.pdf-file {
  padding: 12px;
  max-width: 267px;
  width: fit-content;
  position: relative;
  background: v.$secondary-gray-01;
  cursor: pointer;
  border-radius: 4px;

  &-content {
    display: flex;
    column-gap: 14px;
  }

  &-icon-container {
    width: 44px;
    height: 44px;
    background: v.$color-white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;
  }

  &-info {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &-name {
      @include t.typography('body-2-bold');

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-size {
      @include t.typography('body-3');
    }
  }

}
