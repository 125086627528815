@use 'vars' as v;
@use 'typography' as t;
@use 'mixins' as m;

.mat-mdc-chip {
  &.mat-mdc-standard-chip.mdc-evolution-chip {
    --mdc-chip-label-text-color: #{v.$primary-mia-dark-grey};
    --mdc-chip-with-trailing-icon-trailing-icon-color: #{v.$primary-mia-dark-grey};
    --mdc-chip-elevated-container-color: #{v.$secondary-gray-01};
    --mdc-chip-container-shape-radius: 4px;
    --mdc-chip-container-height: 20px;
    height: auto;
    max-width: var(--mdc-chip-max-width, 475px);
    transition: background-color 150ms linear;
    gap: 8px;
    padding: 12px 4px 12px 12px;
    box-sizing: content-box;
    cursor: pointer;

    &:hover {
      --mdc-chip-label-text-color: #{v.$primary-mia-blue};
      --mdc-chip-with-trailing-icon-trailing-icon-color: #{v.$primary-mia-blue};
      --mdc-chip-elevated-container-color: #{v.$secondary-gray-02};
      .mat-mdc-chip-focus-overlay {
        display: none;
      }
    }

    .mat-mdc-chip-action {
      max-width: 450px;
      &.mdc-evolution-chip__action {
        padding-left: 0;
      }
    }

    .mat-mdc-chip-action-label {
      @include t.typography('body-2-bold');
      word-break: break-all;
      white-space: var(--mdc-chip-action-label-white-space, break-spaces);
    }
    .mat-mdc-chip-remove {
      width: 24px;
      height: 24px;
      opacity: 1;
      font-size: 24px;
    }
  }

  &--invalid {
    &.mat-mdc-standard-chip.mdc-evolution-chip {
      --mdc-chip-label-text-color: #{v.$accent-warning};
      --mdc-chip-elevated-container-color: #{m.bg-opacity(
          v.$accent-warning,
          0.3
        )};
    }
  }
}
