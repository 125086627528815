@use 'vars' as vars;
@use 'typography' as typography;

.mat-mdc-form-field {
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
    --mdc-outlined-text-field-focus-outline-width: 1px;
    --mdc-outlined-text-field-input-text-color: #{vars.$primary-mia-dark-grey};

    letter-spacing: normal;
    height: var(--mdc-text-field-height, 48px);

    &:hover {
      .mat-input-date-value {
        &__day,
        &__month,
        &__year {
          color: vars.$color-white;
          background-color: vars.$primary-mia-blue;
        }
      }

      .mdc-notched-outline
        label.mdc-floating-label.mat-mdc-floating-label.mdc-floating-label--float-above {
        --mdc-outlined-text-field-label-text-color: #{vars.$primary-mia-blue};
      }

      .mat-mdc-form-field-text-suffix {
        color: vars.$primary-mia-blue;
      }

      &:not(:focus-within) .mat-icon {
        --mat-icon-color: #{vars.$primary-mia-blue};
      }
    }

    &:has(mat-chip-grid) {
      .mat-mdc-form-field-flex {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }

    &:has(label) {
      margin-top: 5px;
    }

    .mdc-notched-outline {
      --mat-mdc-form-field-floating-label-scale: 1;
      letter-spacing: normal;
      label.mdc-floating-label.mat-mdc-floating-label.mdc-floating-label--float-above {
        --mat-form-field-outlined-label-text-populated-size: 14px;
        --mdc-outlined-text-field-label-text-weight: 700;
        --mdc-outlined-text-field-label-text-color: #{vars.$primary-mia-dark-grey};
        --mat-mdc-form-field-label-transform: translateY(-37px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
        letter-spacing: normal;
      }
    }

    .mat-mdc-form-field-icon-suffix {
      padding: 0 0 0 10px;

      .mat-icon {
        padding: 0;
      }
    }

    .mat-mdc-form-field-infix {
      position: relative;
      padding-top: var(--mdc-text-field-padding-top, 13px);
      padding-bottom: var(--mdc-text-field-padding-bottom, 13px);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      min-height: var(--mdc-text-field-height, 48px);
      textarea.mat-mdc-input-element {
        height: calc(
          var(--mdc-text-field-height, 48px) - 13px - var(
              --mdc-text-field-padding-bottom,
              13px
            )
        );
      }
      .mat-mdc-input-element {
        font-style: normal;
        width: calc(var(--mdc-text-field-width, 219px) - 32px);
        max-width: 100%;
        &::placeholder {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }
      }
    }

    &.mdc-text-field--disabled {
      --mdc-outlined-text-field-disabled-outline-color: #{vars.$secondary-gray-03};
      background-color: #{vars.$secondary-gray-01};

      label.mdc-floating-label {
        --mdc-outlined-text-field-disabled-label-text-color: #{vars.$secondary-gray-03};
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        top: 23px;
        letter-spacing: normal;
      }
    }

    &.mdc-text-field--invalid {
      --mdc-outlined-text-field-input-text-color: #{vars.$accent-warning};
      label.mdc-floating-label {
        color: vars.$primary-mia-dark-grey;
      }

      .mat-mdc-form-field-icon-suffix {
        .mat-icon {
          color: transparent;
          &::before {
            content: 'error';
            color: #{vars.$accent-warning};
            position: absolute;
            z-index: 1;
          }
        }
      }
    }
  }

  .mat-mdc-form-field-hint-wrapper {
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    font-size: 14px;
    color: vars.$primary-mia-dark-grey;
  }

  textarea {
    resize: none;
  }

  .mdc-floating-label {
    &--required {
      &::after {
        content: '*';
        color: #{vars.$accent-warning};
      }
    }
  }

  &.mat-focused {
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
      &:not(:has(input:placeholder-shown))
        label.mdc-floating-label.mat-mdc-floating-label.mdc-floating-label--float-above,
      &:has(input:placeholder-shown)
        label.mdc-floating-label.mat-mdc-floating-label.mdc-floating-label--float-above {
        --mdc-outlined-text-field-label-text-color: #{vars.$primary-mia-purple};
      }
      --mdc-outlined-text-field-outline-color: #{vars.$primary-mia-purple};
    }
  }

  &.ng-pristine {
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
      label.mdc-floating-label.mat-mdc-floating-label.mdc-floating-label--float-above {
        --mdc-outlined-text-field-label-text-color: #{vars.$primary-mia-dark-grey};
      }
    }
  }

  &.ng-dirty {
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
      label.mdc-floating-label.mat-mdc-floating-label.mdc-floating-label--float-above {
        --mdc-outlined-text-field-label-text-color: #{vars.$secondary-gray-03};
      }
    }
  }

  &:not(.ng-pristine) {
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
      &:not(:has(input:placeholder-shown))
        label.mdc-floating-label.mat-mdc-floating-label.mdc-floating-label--float-above {
        --mdc-outlined-text-field-label-text-color: #{vars.$secondary-gray-03};
      }
    }
  }

  .mat-mdc-form-field-text-suffix {
    @include typography.typography('body-2-bold');
    color: vars.$secondary-gray-03;
  }
}
