@use '@angular/material' as mat;
@use 'sass:map';
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');

$font-family-primary: 'Poppins', sans-serif;
$font-family-secondary: 'Lora', serif;

$headline-1-bold: (
  font-family: $font-family-primary,
  font-size: 32px,
  font-weight: 700,
  line-height: 38px,
  letter-spacing: normal,
);

$headline-1: (
  font-family: $font-family-primary,
  font-size: 32px,
  font-weight: 500,
  line-height: 38px,
  letter-spacing: normal,
);

$headline-2-bold: (
  font-family: $font-family-primary,
  font-size: 24px,
  font-weight: 700,
  line-height: 30px,
  letter-spacing: normal,
);

$headline-2: (
  font-family: $font-family-primary,
  font-size: 24px,
  font-weight: 500,
  line-height: 30px,
  letter-spacing: normal,
);

$headline-3: (
  font-family: $font-family-primary,
  font-size: 20px,
  line-height: 28px,
  letter-spacing: normal,
);

$headline-3-bold: (
  font-family: $font-family-primary,
  font-size: 20px,
  font-weight: 700,
  line-height: 28px,
  letter-spacing: normal,
);

$subtitle-1-bold: (
  font-family: $font-family-primary,
  font-size: 18px,
  font-weight: 700,
  line-height: 24px,
  letter-spacing: normal,
);

$subtitle-1: (
  font-family: $font-family-primary,
  font-size: 18px,
  font-weight: 500,
  line-height: 24px,
  letter-spacing: normal,
);

$body-1-bold: (
  font-family: $font-family-primary,
  font-size: 16px,
  font-weight: 700,
  line-height: 22px,
  letter-spacing: normal,
);

$body-1: (
  font-family: $font-family-primary,
  font-size: 16px,
  font-weight: 500,
  line-height: 22px,
  letter-spacing: normal,
);

$body-2-bold: (
  font-family: $font-family-primary,
  font-size: 14px,
  font-weight: 700,
  line-height: 20px,
  letter-spacing: normal,
);

$body-2: (
  font-family: $font-family-primary,
  font-size: 14px,
  font-weight: 500,
  line-height: 20px,
  letter-spacing: normal,
);

$body-3-bold: (
  font-family: $font-family-primary,
  font-size: 12px,
  font-weight: 700,
  line-height: 18px,
  letter-spacing: normal,
);

$body-3: (
  font-family: $font-family-primary,
  font-size: 12px,
  font-weight: 500,
  line-height: 18px,
  letter-spacing: normal,
);

$button: (
  font-family: $font-family-primary,
  font-size: 14px,
  font-weight: 700,
  line-height: normal,
  letter-spacing: normal,
);

$link: (
  font-family: $font-family-primary,
  font-size: 14px,
  font-weight: 700,
  line-height: 20px,
  letter-spacing: normal,
  text-decoration: none,
);

$typographyMap: (
  'headline-1-bold': $headline-1-bold,
  'headline-1': $headline-1,
  'headline-2-bold': $headline-2-bold,
  'headline-2': $headline-2,
  'headline-3': $headline-3,
  'headline-3-bold': $headline-3-bold,
  'subtitle-1-bold': $subtitle-1-bold,
  'subtitle-1': $subtitle-1,
  'body-1-bold': $body-1-bold,
  'body-1': $body-1,
  'body-2-bold': $body-2-bold,
  'body-2': $body-2,
  'body-3-bold': $body-3-bold,
  'body-3': $body-3,
  'button': $button,
  'link': $link,
);

$typographyTheme: mat.define-typography-config(
  $font-family: $font-family-primary,
  $headline-1:
    mat.define-typography-level(
      $font-size: 32px,
      $line-height: 38px,
      $font-weight: 700,
    ),
  $headline-2:
    mat.define-typography-level(
      $font-size: 32px,
      $line-height: 38px,
      $font-weight: 500,
    ),
  $headline-3:
    mat.define-typography-level(
      $font-size: 24px,
      $line-height: 30px,
      $font-weight: 700,
    ),
);

/**
  $type: 'headline-1-bold', 'headline-1', 'headline-2-bold', 'subtitle-1-bold'
  'subtitle-1', 'body-1-bold', 'body-1', 'body-2-bold', 'body-2', 'body-3-bold',
  'body-3', 'button. 'link'
*/
@mixin typography($type, $font-style: normal, $font-family: primary) {
  @each $property, $value in map.get($typographyMap, $type) {
    #{$property}: #{$value};
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;

  @if ($font-style != 'normal') {
    font-style: $font-style;
  }

  @if ($font-family == 'secondary') {
    font-family: $font-family-secondary;
  }
}
