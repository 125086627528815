@use 'vars' as vars;
@use 'typography' as typography;

button.mdc-button {
  $self: &;
  --mdc-typography-button-letter-spacing: normal;
  --mdc-filled-button-container-shape: 30px;
  --mdc-filled-button-container-height: 44px;

  --mdc-outlined-button-container-shape: 40px;
  --mdc-outlined-button-outline-width: 2px;
  --mat-icon-color: var(--mdc-text-button-label-text-color);

  &.mat-mdc-button.mat-mdc-button-base {
    transition: 100ms ease-in-out;
    @include typography.typography('button');
    padding: 0;
    height: var(--mdc-button-height, 44px);
    & > mat-icon.mat-icon {
      margin-left: 0;
      margin-right: 4px;
    }
    .mdc-button__label + .mat-icon {
      margin-left: 4px;
      margin-right: 0;
    }
  }

  & > mat-icon.mat-icon {
    display: flex;
  }

  &.mdc-button--unelevated,
  &.mdc-button--outlined {
    &.mat-mdc-button-base {
      box-sizing: content-box;
      transition: 100ms ease-in-out;
      @include typography.typography('button');
      width: var(--mdc-button-width, unset);
      min-width: var(--mdc-button-width, 64px);
      height: var(--mdc-button-height, 44px);
      box-sizing: border-box;

      & > .mat-icon {
        font-size: 24px;
        line-height: 24px;
        width: 24px;
        height: 24px;
        margin-right: 4px;
      }
    }
  }

  &.mdc-button--unelevated {
    padding: 4px 34px;

    &:has(mat-icon) {
      padding: 4px 18px;
    }
    &:has(mat-icon[iconPositionEnd]) {
      padding: 6px 12px;
    }
  }

  &.mdc-button--outlined {
    padding: 4px 32px;
    &:has(mat-icon) {
      padding: 4px 16px;
    }
    &:has(mat-icon[iconPositionEnd]) {
      padding: 4px 12px;
    }
  }

  &.mat-mdc-outlined-button:not(:disabled) {
    border-color: unset;
  }

  .mat-ripple,
  .mdc-button__ripple {
    display: none;
  }

  &.mat-primary {
    &:hover {
      --mdc-text-button-label-text-color: #{vars.$primary-mia-blue};
      --mat-icon-color: #{vars.$primary-mia-blue};
    }

    &:active {
      --mdc-text-button-label-text-color: #{vars.$primary-mia-dark-grey};
      --mat-icon-color: #{vars.$primary-mia-dark-grey};
    }

    &:disabled {
      --mdc-text-button-label-text-color: #{vars.$secondary-gray-02};
      --mat-icon-color: #{vars.$secondary-gray-02};
    }
  }

  @at-root &--unelevated.mat-primary,
    &--unelevated.mat-warn {
    --mat-icon-color: var(--mdc-filled-button-label-text-color);

    &:hover {
      --mdc-filled-button-container-color: #{vars.$primary-mia-blue};
      --mat-icon-color: var(--mdc-filled-button-label-text-color);
    }

    &:active {
      --mdc-filled-button-container-color: #{vars.$secondary-gray-03};
      --mat-icon-color: var(--mdc-filled-button-label-text-color);
    }

    &:disabled {
      background: vars.$secondary-gray-02;
      --mdc-filled-button-container-color: #{vars.$secondary-gray-02};
      --mat-icon-color: var(--mdc-filled-button-disabled-label-text-color);
    }
  }

  @at-root &--outlined.mat-primary {
    &:hover {
      --mdc-outlined-button-label-text-color: #{vars.$primary-mia-blue};
      --mat-icon-color: #{vars.$primary-mia-blue};
    }

    &:active {
      --mdc-outlined-button-label-text-color: #{vars.$secondary-gray-03};
      --mat-icon-color: #{vars.$secondary-gray-03};
    }

    &:disabled {
      background: vars.$secondary-gray-01;
      --mdc-outlined-button-label-text-color: #{vars.$secondary-gray-02};
      --mat-icon-color: #{vars.$secondary-gray-02};
    }
  }
}

.mat-mdc-button-base.mat-mdc-fab {
  box-shadow: none;

  &:hover {
    --mdc-fab-container-color: #{vars.$primary-mia-blue};

    box-shadow: none;
  }

  &:not(.mdc-ripple-upgraded):focus {
    box-shadow: none;
  }

  &:active {
    --mdc-fab-container-color: #{vars.$secondary-gray-03};

    box-shadow: none;
  }
}

// More menu button
.mat-mdc-menu-trigger.mdc-icon-button.mat-mdc-icon-button {
  --mdc-icon-button-state-layer-size: 44px;
  border-radius: 4px;
  transition: all 0.2s;
  padding: 0;
  display: grid;
  place-items: center;

  span.mat-mdc-button-touch-target {
    width: var(--mdc-icon-button-state-layer-size);
    height: var(--mdc-icon-button-state-layer-size);
  }

  .mat-mdc-button-persistent-ripple {
    border: 1px solid var(--menu-button-border-color, transparent);
    border-radius: 4px;
    &::before {
      opacity: 0;
    }
  }

  &[aria-expanded='true'] {
    .mat-mdc-button-persistent-ripple {
      border-color: vars.$primary-mia-dark-grey;
    }
  }

  &:hover {
    .mat-mdc-button-persistent-ripple {
      border-color: vars.$primary-mia-blue;
    }
  }
}
