@use 'vars' as vars;
@use 'typography' as typography;

div.mat-mdc-select-panel {
  --mat-option-label-text-color: #{vars.$primary-mia-dark-grey};
  --mat-option-selected-state-label-text-color: #{vars.$primary-mia-dark-grey};
  &.mdc-menu-surface--open {
    margin-top: 4px;
    box-shadow: none;
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px vars.$secondary-gray-03;
    padding: 4px;

    & > .mdc-list-item {
      @include typography.typography('body-2');
      background: vars.$secondary-gray-01;
      min-height: 32px;
      padding-left: 9px;
      padding-right: 9px;
      border-radius: 4px;
      display: flex;
      margin-bottom: 6px;

      &:nth-last-child(1 of :not(.hidden)) {
        margin-bottom: 0;
      }

      &:hover {
        background: vars.$primary-mia-blue;
        --mat-option-label-text-color: #ffffff;
        --mat-option-selected-state-label-text-color: #ffffff;
      }

      b {
        @include typography.typography('body-2-bold');
      }

      &--selected {
        box-shadow: 0 0 0 1px vars.$primary-mia-purple;
        --mat-option-selected-state-layer-color: #{vars.$accent-purple-01};

        &::after {
          font-family: 'Material Icons Round';
          content: 'check_small';
          font-size: 20px;
          line-height: 20px;
          width: 20px;
          transition: transform 200ms ease-in-out;
        }
      }

      .mdc-list-item__primary-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      mat-pseudo-checkbox {
        display: none;
      }
    }
  }
}

.cdk-overlay-pane:not(.mat-mdc-select-panel-above) div.mat-mdc-select-panel {
  &.mdc-menu-surface--open {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.mat-select-time-panel.mat-mdc-select-panel {
  &.mdc-menu-surface--open {
    & > .mdc-list-item {
      background: transparent;
      justify-content: center;
      margin-bottom: 6px;

      &:nth-last-child(1 of :not(.hidden)) {
        margin-bottom: 0;
      }

      &:hover {
        background: vars.$primary-mia-blue;
        --mat-option-label-text-color: #ffffff;
        --mat-option-selected-state-label-text-color: #ffffff;
      }

      &--selected {
        box-shadow: 0 0 0 1px vars.$primary-mia-purple;
        --mat-option-selected-state-layer-color: #{vars.$accent-purple-01};

        &::after {
          display: none;
        }
      }

      & > span {
        margin-right: unset;
      }

      .mdc-list-item__primary-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
