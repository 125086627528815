@use 'vars' as vars;
@use 'typography' as typography;
@use 'mixins' as m;

@mixin status-chip {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 4px;
  width: 76px;
  height: 30px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  user-select: none;
}

.main-table.mat-mdc-table {
  width: 100%;
  white-space: nowrap;

  .mat-mdc-header-row {
    height: 52px;
  }

  .mdc-data-table__content {
    display: block;
    overflow: auto;
  }

  .mdc-data-table__row {
    padding-top: 12px;
    border: none;

    &:first-child {
      padding-top: 20px;

      .mat-mdc-cell {
        padding-top: 0;
      }
    }

    &:last-child .mat-mdc-cell {
      padding-bottom: 20px;
    }
  }

  .mat-mdc-header-cell {
    background: vars.$secondary-gray-01;
    font-size: 14px;
    font-weight: 700;
    color: vars.$primary-mia-dark-grey;
  }

  .mat-mdc-header-cell[data-sort] {
    cursor: pointer;
    color: vars.$secondary-gray-03;
    --mat-icon-color: #{vars.$secondary-gray-03};

    &.active {
      color: vars.$primary-mia-dark-grey;
      --mat-icon-color: #{vars.$primary-mia-dark-grey};
    }

    &:hover {
      color: vars.$primary-mia-blue;
      --mat-icon-color: #{vars.$primary-mia-blue};
    }

    &:active {
      color: vars.$primary-mia-dark-grey;
      --mat-icon-color: #{vars.$primary-mia-dark-grey};
    }

    &.active mat-icon {
      rotate: 0deg;
      &.upsideDown {
        rotate: 180deg;
      }
    }

    p {
      font-size: 14px;
      font-weight: 700;
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 4px;
      align-items: center;

      span {
        @include m.text-ellipsis();
      }
    }

    mat-icon {
      width: 18px;
      height: 18px;
      rotate: 180deg;
    }
  }

  .mat-mdc-cell {
    border-bottom: 1px solid vars.$secondary-gray-02;
    color: vars.$primary-mia-dark-grey;
    height: 60px;
    padding-bottom: 12px;

    @include typography.typography('body-2');
    p,
    span {
      @include typography.typography('body-2');
    }
  }

  .mat-column-actions {
    width: 90px;
  }

  .mat-column-status {
    width: 120px;
  }

  .mat-column-date {
    width: 70px;
    white-space: pre-line;
  }

  .status.mat-mdc-cell {
    &.registered {
      span {
        @include status-chip;

        border: 1px solid vars.$accent-green-dark;
        background: rgba(187, 242, 230, 0.5);
        color: vars.$primary-mia-green;
      }
    }

    &.invited {
      span {
        @include status-chip;

        border: 1px solid vars.$accent-yellow;
        background: rgba(237, 205, 50, 0.5);
        color: vars.$accent-brown;
      }
    }

    &.rejected {
      span {
        @include status-chip;

        border: 1px solid vars.$accent-purple-04;
        background: vars.$accent-purple-01;
        color: vars.$primary-mia-purple;
      }
    }

    &.ongoing {
      span {
        @include status-chip;

        border: solid 1px transparent;
        border-radius: 2px;
        padding: 0;
        background-image:
          linear-gradient(292.4deg, #CDF97D 4.94%, #FFE771 53.41%, #CFC2FF 100%, #DED5FF 100%),
          linear-gradient(147.82deg, #DFD6FF 27.73%, #E6D399 54.21%, #EDD032 80.69%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        color: vars.$accent-brown;
      }
    }
  }

  .multiline.mat-mdc-cell {
    white-space: normal;
    word-break: break-word;
  }

  .empty-state {
    font-style: italic;
    color: vars.$secondary-gray-03;
    user-select: none;
  }

  .name-container {
    display: flex;
    align-items: center;
    column-gap: 6px;
  }

  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  .role-container {
    display: flex;
    align-items: center;
    column-gap: 6px;

    .mat-icon {
      cursor: pointer;
    }
  }

  .actions {
    color: vars.$primary-mia-purple;
    cursor: pointer;
    display: flex;
    margin: auto;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  thead {
    border: 1px solid vars.$secondary-gray-03;
    border-bottom: none;
    overflow: hidden;
  }

  &:not(.main-table--no-header-border-radius) thead {
    border-radius: 8px 8px 0 0;
  }

  tbody {
    border: 1px solid vars.$secondary-gray-03;
    border-top: 0;
    overflow: hidden;
    border-radius: 0 0 8px 8px;
  }

  .mat-mdc-header-cell {
    border: 0;
  }
}
