@use 'typography' as t;
@use 'vars' as v;

.mat-calendar-body-label {
  opacity: 0;
  padding: 0 !important;
}

.mat-calendar-body-label[colspan='7'] {
  display: none;
}

tr:has(.mat-calendar-table-header-divider),
tr:has(.mat-calendar-body-label[colspan='7']) {
  display: none;
}

.mat-calendar-table-header-divider {
  display: none;
}

.mat-calendar-table-header {
  --mat-datepicker-calendar-header-text-color: #{v.$secondary-gray-03};
  span {
    @include t.typography('body-3-bold');
  }
}

.mat-datepicker-content
  .mat-datepicker-content-container-with-custom-header
  .mat-calendar {
  width: 206px;
  height: 179px;

  .mat-calendar-content {
    padding: 0 3px 3px 3px;
    .mat-calendar-table-header th {
      padding: 0;
    }

    .mat-calendar-body-cell-content {
      border-radius: 2px;
      width: 20px;
      height: 18px;
      display: flex;
    }
  }
  .mat-calendar-table {
    border-spacing: 5px 5px;
    border-collapse: separate;
  }

  td.mat-calendar-body-cell-container {
    width: 20px !important;
    height: 18px !important;
    padding: 0 !important;

    button {
      margin-left: 1px;
      width: 20px;
    }
  }
}

mat-datepicker-content.mat-datepicker-content {
  --mat-datepicker-calendar-date-text-color: v.$primary-mia-dark-grey;
  --mat-datepicker-calendar-date-today-selected-state-outline-color: transparent;
  --mat-datepicker-calendar-date-today-outline-color: transparent;
  --mat-datepicker-calendar-date-selected-state-background-color: #{v.$accent-purple-01};
  --mat-datepicker-calendar-date-selected-state-text-color: #{v.$primary-mia-purple};
  --mat-datepicker-calendar-date-hover-state-background-color: #{v.$primary-mia-blue};
  box-shadow: none;
  border: 1px solid v.$primary-mia-purple;
  margin-top: 4px;
  margin-bottom: 8px;
  margin-left: -3px;

  .mat-calendar-body-cell {
    &:hover {
      --mat-datepicker-calendar-date-text-color: #{v.$color-white};
    }
  }
  .mat-calendar-body-cell-content {
    @include t.typography('body-3-bold');
    &.mat-calendar-body-selected {
      border-color: v.$primary-mia-purple;
    }
  }
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-text-field__input {
  &.mat-datepicker-input {
    visibility: hidden;
  }
}
