$light-primary-text: #ffffff;
$color-white: #ffffff;
$color-black: #000000;

$primary-mia-purple: #5c3ccd;
$primary-mia-blue: #1e0478;
$primary-mia-dark-grey: #2e2e40;
$primary-mia-green: #1d7e84;
$secondary-gray-01: #f2f2f2;
$secondary-gray-02: #d4d4db;
$secondary-gray-03: #79798a;
$secondary-gray-04: #fcfcfc;
$accent-warning: #e8430e;
$accent-purple-01: #dfd6ff;
$accent-purple-02: #cfc2ff;
$accent-purple-03: #af99ff;
$accent-purple-04: #8f70ff;
$accent-purple-05: #F3F0FF;
$accent-yellow: #edcd32;
$accent-green-dark: #26cba8;
$accent-green-light: #bbf2e6;
$accent-brown: #601502;
$graph-average: #dad0ff;

$break-points: (
  small: 1366px,
  medium: 1440px,
  semi-large: 1600px,
  large: 1920px,
);

:root {
  --primary-mia-purple: #{$primary-mia-purple};
  --primary-mia-blue: #{$primary-mia-blue};
  --primary-mia-dark-grey: #{$primary-mia-dark-grey};
  --secondary-gray-01: #{$secondary-gray-01};
  --secondary-gray-02: #{$secondary-gray-02};
  --secondary-gray-03: #{$secondary-gray-03};
  --accent-warning: #{$accent-warning};
}
